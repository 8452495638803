import { Box, Heading, ResponsiveContext, Text, Paragraph } from 'grommet'
import IMG1 from '../../assets/facility.jpg'

function Hero() {
  return (
    <ResponsiveContext.Consumer>
      {(size) => size === 'small'
        ? (
            <Box
              id='home'
              justify='center'
              align='center'
              background={{
                image: `url(${IMG1})`
              }}
            >
                <Box
                      fill
                      margin={{ top: '4.5rem'}}
                      background={{
                        color: "#000B18",
                        opacity: 'strong'
                      }}
                      pad={{ horizontal: 'medium', vertical: 'large'}}
                      justify='center'
                      
                    >
                      <Box
                        width='medium'
                        justify='center'
                        pad={{ vertical: 'medium'}}
                      >
                        <Heading
                            margin={{
                              vertical: 'medium'
                            }}
                            color="white"
                            size='small'
                            level='2'
                          >
                            There's a lot to love at our facility
                          </Heading>
                          <Text
                            margin={{
                              bottom: 'xsmall'
                            }}
                            size='small'
                          >
                            Our communities offer the comforts of home, without the work. Our care solutions are designed around you and the lifeyou want here.
                          </Text>
                      </Box>
                    </Box>
            </Box>
          )
        : (
          <Box
              id='home'
              height='medium'
              background={{
                image: `url(${IMG1})`
              }}
            >
                <Box
                    fill
                    background={{
                      color: "#000B18",
                      opacity: 'strong'
                    }}
                  >
                    <Box
                      alignSelf='center'
                      width='1000px'
                      pad={{ vertical: 'medium'}}
                    >
                      <Heading fill='horizontal'>
                        There's a lot to love at our facility
                      </Heading>
                      <Box
                        background='white'
                        pad='medium'
                        width='medium'
                        
                      >
                        <Paragraph >
                          Our communities offer the comforts of home, without the work. Our care solutions are designed around you and the lifeyou want here.
                        </Paragraph>
                      </Box>
                    </Box>
                  </Box>
            </Box>
        )
      }
    </ResponsiveContext.Consumer>
  )
}

export default Hero
