import { Box, Heading, Image, ResponsiveContext, Text } from 'grommet'
import IMG1 from '../../assets/bryan.jpg'
import Map from '../../assets/map.png'

function Community() {
  return (
    <ResponsiveContext.Consumer>
      {(size) => size === 'small'
        ? (
            <Box
              id='location'
              direction='column'
              align='center'
              justify='center'
              background='white'
              border={{
                color: '#006ee6',
                side: 'bottom',
                size: 'xsmall'
            }}
            >
              <Box direction='row-responsive' pad={{ vertical: 'medium' }}>
                  <Box height='small'>
                    <Image
                      fit='contain'
                      src={Map}
                    />
                  </Box>
                  <Box pad={{ vertical: 'small', horizontal: 'large'}}>
                    <Heading
                      level='4'
                      color='black'
                      margin='none'
                    >
                      Facility Location
                    </Heading>
                    <Text weight='bold' size='small'>703 Dean St.</Text>
                    <Text weight='bold' size='small'>Bryan, Tx 77803</Text>
                    <Text weight='bold' size='small'>Cell: (979) 255-9016</Text>
                    <Text weight='bold' size='small'>Office: (979) 324-5981</Text>
                    <Text weight='bold' size='small'>Email: tinagreen402@gmail.com</Text>
                  </Box>
                </Box>
              <Box
                background={{
                  image: `url(${IMG1})`
                }}
              >
                <Box 
                  pad={{ horizontal: 'large', vertical: 'medium' }}
                  background={{
                    color: "#000B18",
                    opacity: 'strong'
                  }}
                >
                  <Heading
                    margin={{
                      vertical: 'medium'
                    }}
                    level='4'
                    color='white'
                  >
                    Nearby points of interest
                  </Heading>
                  <Text weight='bold' size='small' color='white'>
                    Nearby:
                  </Text>
                  <Text weight='bold' size='small' color='white'>Hospitals:</Text><Text size='small' color='white'>Scott  White, Physician Centre, St. Joseph’s Hospital</Text>
                  <Text weight='bold' size='small' color='white'>Pharmacies:</Text><Text size='small' color='white'>Walgreens, CVS, Kroger, H-E-B, Walmart</Text>
                  <Text weight='bold' size='small' color='white'>Dining:</Text><Text size='small' color='white'>Cotton Patch Café, Lupe Tortilla, On The Border, CJ’s BBQ, Olive Garden, Fish Daddy’s, Cheddar’s, The Republic</Text>
                  <Text weight='bold' size='small' color='white'>Shopping:</Text><Text size='small' color='white'>Post Oak Mall, Walmart, Kroger, H-E-B, ALDI</Text>
                  <Text weight='bold' size='small' color='white'>Local destinations:</Text><Text size='small' color='white'>Bonfire Memorial, Santa’s Wonderland, Brazos Valley Farmer’s Market</Text>
                </Box>
              </Box>
            </Box>
        )
        : (
            <Box
              id='location'
              align='center'
              background='white'
            >
              <Box
                width='1000px'
              >
                <Box direction='row-responsive'>
                  <Box
                    basis='1/2'
                    pad='small'
                    height='medium'
                  >
                    <Image
                      fit='contain'
                      src={Map}
                    />
                  </Box>
                  <Box
                    basis='1/2'
                    pad='small'
                    height='medium'
                  >
                    <Heading
                      margin={{
                        top: 'large'
                      }}
                      level='4'
                      color='black'
                    >
                      Facility Location
                    </Heading>
                    <Text weight='bold' size='small'>703 Dean St.</Text>
                    <Text weight='bold' size='small'>Bryan, Tx 77803</Text>
                    <Text weight='bold' size='small'>Cell: (979) 255-9016</Text>
                    <Text weight='bold' size='small'>Office: (979) 324-5981</Text>
                    <Text weight='bold' size='small'>Email: tinagreen402@gmail.com</Text>
                  </Box>
                </Box>
                <Box
                  direction='row-responsive'
                >
                  <Box
                    basis='1/2'
                    pad='small'
                    height='medium'
                  >
                    <Heading
                      margin={{
                        vertical: 'small'
                      }}
                      level='4'
                      color='black'
                    >
                      Nearby Points of Interest
                    </Heading>
                    <Text weight='bold' size='small'>Hospitals:</Text><Text size='small'>Scott  White, Physician Centre, St. Joseph’s Hospital</Text>
                    <Text weight='bold' size='small'>Pharmacies:</Text><Text size='small'>Walgreens, CVS, Kroger, H-E-B, Walmart</Text>
                    <Text weight='bold' size='small'>Dining:</Text><Text size='small'>Cotton Patch Café, Lupe Tortilla, On The Border, CJ’s BBQ, Olive Garden, Fish Daddy’s, Cheddar’s, The Republic</Text>
                    <Text weight='bold' size='small'>Shopping:</Text><Text size='small'>Post Oak Mall, Walmart, Kroger, H-E-B, ALDI</Text>
                    <Text weight='bold' size='small'>Local destinations:</Text><Text size='small'>Bonfire Memorial, Santa’s Wonderland, Brazos Valley Farmer’s Market</Text>
                  </Box>
                  <Box
                    basis='1/2'
                    height='medium'
                  >
                    <Image 
                      fit='contain'
                      src={IMG1}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
        )}
    </ResponsiveContext.Consumer>
  )
}

export default Community