import { Box, Heading, Paragraph, ResponsiveContext, Text } from 'grommet'
import { FaBed, FaCarSide, FaBath } from 'react-icons/fa'
import { AiFillCheckCircle } from 'react-icons/ai'

function BannerOne() {
  return (
    <ResponsiveContext>
      {(size) => size === 'small'
        ? 
          (
            <Box
              align='center'
              justify='center'
              background={{
                  color: 'white'
                }}
              id='about'
            >
              <Box
                  width='1000px'
                  pad='medium'
                  gap='small'
              >
                  <Box>
                      <Heading
                          fill
                          size='medium'
                          level='2'
                          margin={{
                              vertical:'none'
                          }}
                          color='black'
                          textAlign='center'
                      >
                          Senior Assisted Living located in Bryan, TX
                      </Heading>
                      <Box width="680px" alignSelf='center' margin='small'>
                          <Paragraph
                              fill
                              textAlign='center'
                              color='black'
                              margin='none'
                          >
                              When you step in the front door of our senior community, you will immediatly notice the homelike feel and comfortable atmosphere.
                          </Paragraph>
                          <Paragraph
                              fill
                              textAlign='center'
                              color='black'
                              margin='none'
                          >
                              Our caring team members are always available to offer a helping hand and there is never a shortage of activities to take part in! We offer the following living options:
                          </Paragraph>
                      </Box>
                  </Box>
                  <Box
                    direction='row-responsive'
                    align='center'
                    justify='center'
                    gap='medium'
                  >
                    <Box
                      background='#708090'
                      pad={{ vertical:'large', horizontal: 'xlarge'}}
                      gap='large'
                      style={{ borderRadius: '2rem'}}
                      direction='row'
                    >
                      <FaBed 
                        color='white' 
                        style={{
                          marginTop: '1rem'
                        }}
                      />
                      <Box>
                        <Text color='white' weight='bold'>Five Bedrooms</Text>
                        <Text color='white' weight='bold'>Double beds in each room</Text>
                      </Box>
                    </Box>
                    <Box
                      background='#708090'
                      pad={{ vertical:'large', horizontal: 'xlarge'}}
                      gap='large'
                      style={{ borderRadius: '2rem'}}
                      direction='row'
                      align=''
                    >
                      <FaBath 
                        style={{
                          marginTop: '0.25rem'
                        }}
                        color='white'
                      />
                        <Text color='white' weight='bold'>Three bathrooms</Text>
                    </Box>
                    <Box
                      background='#708090'
                      pad={{ vertical:'large', horizontal: 'xlarge'}}
                      gap='large'
                      style={{ borderRadius: '2rem'}}
                      direction='row'
                      align=''
                    >
                      <FaCarSide 
                        style={{
                          marginTop: '0.25rem'
                        }}
                        color='white'
                      />
                        <Text color='white' weight='bold'>Transport Van</Text>
                    </Box>
              </Box>

              <Box    
                pad='medium'
                align='center'
                justify='center'
              >
                <Heading
                    fill
                    size='medium'
                    level='2'
                    margin={{
                        vertical:'none'
                    }}
                    color='black'
                    textAlign='center'
                >
                    Amenities
                </Heading>
                <Box direction='row-responsive'>
                    <Box
                        pad='small'
                        gap='small'
                    >
                        <Box direction='row' gap='small'>
                            <AiFillCheckCircle color='#708090' style={{ marginTop: '3px' }}/>
                            <Text>Three hot meals a day plus a snack</Text>
                        </Box>
                        <Box direction='row' gap='small'>
                            <AiFillCheckCircle color='#708090' style={{ marginTop: '3px' }}/>
                            <Text>Church and Bible study</Text>
                        </Box>
                        <Box direction='row' gap='small'>
                            <AiFillCheckCircle color='#708090' style={{ marginTop: '3px' }}/>
                            <Text>Celebrate birthdays and holidays</Text>
                        </Box>
                        <Box direction='row' gap='small'>
                            <AiFillCheckCircle color='#708090' style={{ marginTop: '3px' }}/>
                            <Text>Part Time RN</Text>
                        </Box>
                        <Box direction='row' gap='small'>
                            <AiFillCheckCircle color='#708090' style={{ marginTop: '3px' }}/>
                            <Text>All workers are Certified CNA</Text>
                        </Box>
                        <Box direction='row' gap='small'>
                            <AiFillCheckCircle color='#708090' style={{ marginTop: '3px' }}/>
                            <Text>Games and Activities</Text>
                        </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            )
        : (
            <Box
              align='center'
              justify='center'
              background={{
                  color: 'white'
                }}
              id='about'
            >
              <Box
                  width='1000px'
                  pad='medium'
              >
                  <Box>
                      <Heading
                          fill
                          size='medium'
                          level='2'
                          margin={{
                              vertical:'none'
                          }}
                          color='black'
                          textAlign='center'
                      >
                          Senior Assisted Living located in Bryan, TX
                      </Heading>
                      <Box width="680px" alignSelf='center' margin='small'>
                          <Paragraph
                              fill
                              textAlign='center'
                              color='black'
                              margin='none'
                          >
                              When you step in the front door of our senior community, you will immediatly notice the homelike feel and comfortable atmosphere.
                          </Paragraph>
                          <Paragraph
                              fill
                              textAlign='center'
                              color='black'
                              margin='none'
                          >
                              Our caring team members are always available to offer a helping hand and there is never a shortage of activities to take part in! We offer the following living options:
                          </Paragraph>
                      </Box>
                  </Box>
                  <Box
                        direction='row-responsive'
                        align='center'
                        justify='center'
                        gap='large'
                      >
                        <Box
                          width='small'
                          height='250px'
                          background='#708090'
                          pad='medium'
                          gap='small'
                          style={{ borderRadius: '2rem'}}
                        >
                          <FaBed 
                            color='white' 
                            style={{
                              marginTop: '1rem'
                            }}
                  />
                  <Text color='white' weight='bold'>Five Bedrooms</Text>
                  <Text color='white' weight='bold'>Double beds in each room</Text>
                </Box>
                <Box
                  width='small'
                  height='250px'
                  background='#708090'
                  pad='medium'
                  gap='small'
                  style={{ borderRadius: '2rem'}}
                >
                  <FaBath
                    color='white' 
                    style={{
                      marginTop: '1rem'
                    }}
                  />
                  <Text color='white' weight='bold'>Three bathrooms</Text>

                </Box>
                <Box
                  width='small'
                  background='#708090'
                  pad='medium'
                  gap='small'
                  style={{ borderRadius: '2rem'}}
                  height='250px'
                >
                  <FaCarSide
                    color='white' 
                    style={{
                      marginTop: '1rem'
                    }}
                  />
                  <Text color='white' weight='bold'>Transport Van</Text>
                </Box>
              </Box>

              <Box    
                pad='medium'
                align='center'
                justify='center'
              >
                <Heading
                    fill
                    size='medium'
                    level='2'
                    margin={{
                        vertical:'none'
                    }}
                    color='black'
                    textAlign='center'
                >
                    Amenities
                </Heading>
                <Box direction='row-responsive'>
                    <Box
                        pad='small'
                        gap='small'
                    >
                        <Box direction='row' gap='small'>
                            <AiFillCheckCircle color='#708090' style={{ marginTop: '3px' }}/>
                            <Text>Three hot meals a day plus a snack</Text>
                        </Box>
                        <Box direction='row' gap='small'>
                            <AiFillCheckCircle color='#708090' style={{ marginTop: '3px' }}/>
                            <Text>Church and Bible study</Text>
                        </Box>
                        <Box direction='row' gap='small'>
                            <AiFillCheckCircle color='#708090' style={{ marginTop: '3px' }}/>
                            <Text>Celebrate birthdays and holidays</Text>
                        </Box>
                    </Box>
                    <Box
                        pad='small'
                        gap='small'
                    >
                        <Box direction='row' gap='small'>
                            <AiFillCheckCircle color='#708090' style={{ marginTop: '3px' }}/>
                            <Text>Part Time RN</Text>
                        </Box>
                        <Box direction='row' gap='small'>
                            <AiFillCheckCircle color='#708090' style={{ marginTop: '3px' }}/>
                            <Text>All workers are Certified CNA</Text>
                        </Box>
                        <Box direction='row' gap='small'>
                            <AiFillCheckCircle color='#708090' style={{ marginTop: '3px' }}/>
                            <Text>Games and Activities</Text>
                        </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )
      }
    </ResponsiveContext>
  )
}

export default BannerOne