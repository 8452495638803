import { Box } from 'grommet';
import { BannerOne, Community, Hero, Panel, Schedule } from '../components';

function Home() {
  return (
   <Box>
      <Hero/>
      <BannerOne />
      <Panel />
      <Community />
      <Schedule/>
    </Box>
  )
}

export default Home