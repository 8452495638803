import { Box, Button, Form, FormField, Heading, ResponsiveContext, TextInput } from 'grommet'
import { useRef, useState } from 'react'
import { StatusGood } from 'grommet-icons'
import emailjs from '@emailjs/browser';

function Schedule() {
  const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const form = useRef();

    const sendEmail = async (e) => {
        e.preventDefault();
        var body = `
        name: ${name}
        phone: ${phone}
        email: ${email}`
                        
        emailjs.sendForm('service_xsn8o3m', 'template_c27qb0g', form.current, '5-aE0hdZ4fPmHoTJk')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });

          console.log(body)
          setName('')
          setPhone('')
          setEmail('')
      };

  return (
    <ResponsiveContext.Consumer>
      {(size) => size === 'small'
        ? (
          <Box background='#708090' id='contact'>
          <Box pad='large'>
            <Heading color='white'>
              Contact Us
            </Heading>
            <Form   
                    ref={form}
                    validate='submit'
                    onChange={(value) => console.log('Change', value)}
                    onSubmit={sendEmail}
                    onReset={() => {
                        setName('')
                        setPhone('')
                        setEmail('')
                    }}
                >
                    <FormField 
                        label="Name"
                        name="name"
                        required
                        validate={[
                            { regexp: /^[a-z]/i },
                            (name) => {
                            if (name && name.length === 1) return 'must be >1 character';
                            return undefined;
                            },
                            (name) => {
                                if (name === 'good')
                                return {
                                    message: (
                                    <Box align="end">
                                        <StatusGood />
                                    </Box>
                                    ),
                                    status: 'info',
                                };
                                return undefined;
                            },
                        ]}
                    >
                        <TextInput
                        name="name"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        />
                    </FormField>

                    <FormField
                        label="Phone Number"
                        name="phone"
                        required
                        validate={[
                            { regexp: /[0-9]/g },
                            (phone) => {
                            if (phone && phone.length === 1) return 'must be >1 character';
                            return undefined;
                            },
                            (phone) => {
                                if (phone === 'good')
                                return {
                                    message: (
                                    <Box align="end">
                                        <StatusGood />
                                    </Box>
                                    ),
                                    status: 'info',
                                };
                                return undefined;
                            },
                        ]}
                    >
                        <TextInput
                        name="phone"
                        value={phone}
                        onChange={(event) => setPhone(event.target.value)}
                        />
                    </FormField>

                    <FormField
                        label="Email"
                        name="email"
                        required
                        validate={[
                            { regexp: /^[a-z]/i },
                            (email) => {
                            if (email && email.length === 1) return 'must be >1 character';
                            return undefined;
                            },
                            (email) => {
                                if (email === 'good')
                                return {
                                    message: (
                                    <Box align="end">
                                        <StatusGood />
                                    </Box>
                                    ),
                                    status: 'info',
                                };
                                return undefined;
                            },
                        ]}
                    >
                        <TextInput
                        name="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        />
                    </FormField>

                    <Box direction="row" justify="between" margin={{ top: 'medium' }} gap='small'>
                        <Button color='#000B18' primary type="submit" label="Send" />
                    </Box>
                </Form>
          </Box>
        </Box>
        )
        : (
            <Box background='#708090' id='contact'>
              <Box
                width='1000px'
                alignSelf='center'
                pad={{ top: 'medium', bottom: 'large'}}
              >
                <Heading color='white'>
                  Contact Us
                </Heading>
              <Form   
                        ref={form}
                        validate='submit'
                        onChange={(value) => console.log('Change', value)}
                        onSubmit={sendEmail}
                        onReset={() => {
                            setName('')
                            setPhone('')
                            setEmail('')
                        }}
                    >
                        <FormField 
                            label="Name"
                            name="name"
                            required
                            validate={[
                                { regexp: /^[a-z]/i },
                                (name) => {
                                if (name && name.length === 1) return 'must be >1 character';
                                return undefined;
                                },
                                (name) => {
                                    if (name === 'good')
                                    return {
                                        message: (
                                        <Box align="end">
                                            <StatusGood />
                                        </Box>
                                        ),
                                        status: 'info',
                                    };
                                    return undefined;
                                },
                            ]}
                        >
                            <TextInput
                            name="name"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            />
                        </FormField>

                        <FormField
                            label="Phone Number"
                            name="phone"
                            required
                            validate={[
                                { regexp: /[0-9]/g },
                                (phone) => {
                                if (phone && phone.length === 1) return 'must be >1 character';
                                return undefined;
                                },
                                (phone) => {
                                    if (phone === 'good')
                                    return {
                                        message: (
                                        <Box align="end">
                                            <StatusGood />
                                        </Box>
                                        ),
                                        status: 'info',
                                    };
                                    return undefined;
                                },
                            ]}
                        >
                            <TextInput
                            name="phone"
                            value={phone}
                            onChange={(event) => setPhone(event.target.value)}
                            />
                        </FormField>

                        <FormField
                            label="Email"
                            name="email"
                            required
                            validate={[
                                { regexp: /^[a-z]/i },
                                (email) => {
                                if (email && email.length === 1) return 'must be >1 character';
                                return undefined;
                                },
                                (email) => {
                                    if (email === 'good')
                                    return {
                                        message: (
                                        <Box align="end">
                                            <StatusGood />
                                        </Box>
                                        ),
                                        status: 'info',
                                    };
                                    return undefined;
                                },
                            ]}
                        >
                            <TextInput
                            name="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            />
                        </FormField>

                        <Box direction="row" justify="between" margin={{ top: 'medium' }} gap='small'>
                            <Button color='#000B18' primary type="submit" label="Send" />
                        </Box>
                    </Form>
              </Box>
            </Box>
        )
      }
    </ResponsiveContext.Consumer>
  )
}

export default Schedule